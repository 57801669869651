import './Avatar.scss';

export default function Avatar({src}) {
    return (
        <div className="avatar">
            <div className="avatar-inner" >
                <img src={src} style={{ width: '100%' }} />
            </div>
        </div>
    );
}