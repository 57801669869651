import Header from './components/Header';
import Avatar from './components/Avatar';
import Footer from './components/Footer';
import data from './assets/data.json';
import './animate.scss';
import './App.scss';
import Fireflies from './components/Fireflies';
import avatarSrc from './assets/avatar.png';
import qr from './assets/qr-code.png';

function App() {
	return (
		<div className="container">
			<div className="app fade-in">
				<Fireflies/>
				<Header data={data} />
				<Avatar src={avatarSrc} />
				<Footer socials={data.socials} qr={qr} />
			</div>
		</div>
  );
}

export default App;
