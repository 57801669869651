import './Header.scss';
export default function Header({ data }) {
    return (
        <div className="header">
            <div className="personal-info">
                <h1 className="full-name" >{data.name}</h1>
                <h2 className="expertise" >{data.expertise}</h2>
            </div>
            <div className="expertise-lists">
                <span title="NodeJs" className="expertise-skill expertise-node"><i class="icon-node-dot-js"></i></span>
                <span title="PHP" className="expertise-skill expertise-php"><i class="icon-php-elephant"></i></span>
                <span title="WordPress" className="expertise-skill expertise-wp"><i class="icon-wordpress"></i></span>
                <span title="React" className="expertise-skill expertise-react"><i class="icon-react"></i></span>
                <span title="AWS" className="expertise-skill expertise-aws" ><i class="icon-amazonaws"></i></span>
            </div>
        </div>
    );
}